/*Oranges*/
$orange-main: #e78b44;
$orange-bg: #fdf1e7;
$orange-alt: #d37c39;
$orange-bg-wave: #fef9f5;
/*Blues*/
$blue-banner: #d6f6f5;

$blue-bg: #e9f5f5;
$blue-bg-alt: #d7f5f5;

$light-blue: #eaf9f8;

/*White*/
$white: #fff;

/*Menu*/
$menu-grey: #a7a8b0;

/*Greys*/
$light-grey: #f7f8f9;
$light-grey-alt: #f0f3f5;

$dark-grey: #222222;
$dark-grey-alt: #dee2e4;

$grey: #3d3d3d;
$grey-alt: #999fab;

/*Reds*/
$red: #d8324a;
$red-alt: #b42b3f;
$Light-red: #f4cdc9;

/**/
$light-cream: #ffe7d7;

/*Greens*/
$green: #44b762;
$green-alt: #369b50;
