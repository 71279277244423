@import"colours.scss";
@import"fonts.scss";

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none !important;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}

.btn-full{
  width:100%;
}

.btn-main {
  background-color: $orange-main;
  color: $white;
  border: 1px solid $orange-main;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: $content-sm;
  padding: 8px 30px 6px 30px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  opacity: 1;
}

.btn-main:hover {
  background-color: transparent;
  color: $orange-main;
}

.btn-main:focus {
  background-color: transparent;
  color: $orange-main;
  text-decoration: none;
}

.btn-main:active {
  background-color: transparent;
  color: $orange-main;
  text-decoration: none;
}

.btn-main:disabled {
  background-color: #d9e0e6;
  border: 1px solid #d9e0e6;
  color: #717680;
  text-decoration: none;
  opacity: 0.6;
}

.btn-error {
  background-color: $red;
  color: $white;
  border: 1px solid $red;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: $content-sm;
  padding: 8px 30px 6px 30px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.btn-error:hover {
  background-color: transparent;
  color: $red;
}

.btn-error:focus {
  background-color: transparent;
  color: $red;
  text-decoration: none;
}

.btn-error:active {
  background-color: transparent;
  color: $red;
  text-decoration: none;
}

a.btn-main {
  background-color: $orange-main;
  color: $white;
  border: 1px solid $orange-main;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: $content-sm;
  padding: 8px 30px 6px 30px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

a.btn-main:hover {
  background-color: transparent;
  color: $orange-main;
  text-decoration:none;
}

a.btn-main:focus {
  background-color: transparent;
  color: $orange-main;
  text-decoration: none;
}

a.btn-main:active {
  background-color: transparent;
  color: $orange-main;
  text-decoration: none;
}

.btn-simple {
  color: $orange-main;
  border-bottom: 1px solid transparent;
  font-size: $content-sm;
  padding: 5px 2px 1px 0px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.btn-simple:hover {
  color: $orange-main;
  border-bottom: 1px solid $orange-main;
}

.btn-simple:focus {
  color: $orange-main;
  border-bottom: 1px solid $orange-main;
}

.btn-simple:active {
  color: $orange-main;
  border-bottom: 1px solid $orange-main;
}

a.btn-simple {
  color: $orange-main;
  border-bottom: 1px solid transparent;
  font-size: $content-sm;
  padding: 5px 2px 1px 0px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

a.btn-simple:hover {
  color: $orange-main;
  border-bottom: 1px solid $orange-main;
  text-decoration: none;
}

a.btn-simple:focus {
  color: $orange-main;
  border-bottom: 1px solid $orange-main;
  text-decoration: none;
}

a.btn-simple:active {
  color: $orange-main;
  border-bottom: 1px solid $orange-main;
  text-decoration: none;
}

a.btn-main-link {
  display: block;
  text-decoration: none;
  background-color: $orange-main;
  color: $white;
  border: 1px solid $orange-main;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: $content-xs;
  padding: 8px 15px 6px 15px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  text-transform: uppercase;
}

a.btn-main-link:hover {
  background-color: $orange-alt;
  color: $white;
  text-decoration: none !important;
}

a.btn-main-link:focus {
  background-color: $orange-alt;
  color: $white;
  text-decoration: none !important;
}

a.btn-main-link:active {
  background-color: $orange-alt;
  color: $white;
  text-decoration: none !important;
}

a.btn-main-icon {
  display: block;
  text-decoration: none;
  background-color: $orange-main;
  color: $white;
  border: 1px solid $orange-main;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size: $content-xs;
  padding: 15px 20px 13px 20px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  text-transform: uppercase;
}

a.btn-main-icon:hover {
  background-color: $orange-alt;
  color: $white;
  text-decoration: none !important;
}

a.btn-main-icon:focus {
  background-color: $orange-alt;
  color: $white;
  text-decoration: none !important;
}


a.btn-main-icon:active {
  background-color: $orange-alt;
  color: $white;
  text-decoration: none !important;
}
