@import"buttons.scss";
@import"fonts.scss";
@import"colours.scss";

$footer-size: 290px;

.clear {
  clear: both;
}

.mobile-clear {
  clear: none;
}

.centered {
  text-align: center !important;
}

.centered-form {
  text-align: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.centered input {
  text-align: center !important;
}

.nopadding {
  padding: 0 !important;
}

.nopadding-left {
  padding-left: 0 !important;
}

.nopadding-right {
  padding-right: 0 !important;
}

.nopadding-mobile {
  padding: unset;
}

.push-down-5 {
  padding-top: 5px;
}

.push-down-10 {
  padding-top: 10px;
}

.push-down-15 {
  padding-top: 15px;
}

.push-down-20 {
  padding-top: 20px;
}

.push-down-25 {
  padding-top: 25px;
}

.push-down-30 {
  padding-top: 30px;
}

.push-down-35 {
  padding-top: 35px;
}

.push-down-40 {
  padding-top: 40px;
}

.push-down-50 {
  padding-top: 50px;
}

.push-down-60 {
  padding-top: 60px;
}

.push-down-70 {
  padding-top: 70px;
}

.push-down-80 {
  padding-top: 80px;
}

.push-down-90 {
  padding-top: 90px;
}

.push-down-100 {
  padding-top: 100px;
}

.push-down-110 {
  padding-top: 110px;
}

.push-down-120 {
  padding-top: 120px;
}

.push-up-5 {
  padding-bottom: 5px;
}

.push-up-10 {
  padding-bottom: 10px;
}

.push-up-15 {
  padding-bottom: 15px;
}

.push-up-20 {
  padding-bottom: 20px;
}

.push-up-25 {
  padding-bottom: 25px;
}

.push-up-30 {
  padding-bottom: 30px;
}

.push-up-35 {
  padding-bottom: 35px;
}

.push-up-40 {
  padding-bottom: 40px;
}

.push-up-50 {
  padding-bottom: 50px;
}

.push-up-60 {
  padding-bottom: 60px;
}

.push-up-70 {
  padding-bottom: 70px;
}

.push-up-80 {
  padding-bottom: 80px;
}

.push-up-90 {
  padding-bottom: 90px;
}

.push-up-100 {
  padding-bottom: 100px;
}

.push-up-110 {
  padding-bottom: 110px;
}

.push-up-120 {
  padding-bottom: 120px;
}

table {
  width: 100%;
  text-align: left;
}

img {
  width: 100%;
}

.menu-outer {
  padding-top: 20px;
  z-index: 10;
  position: absolute;
  left: 0;
  width: 90%;
  max-width: 1170px;
  margin: 0px auto;
  right: 0;
  top: 0;
  z-index: 999999;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.body-outer {
  min-height: calc(100vh - 290px);
}

.footer-outer {
  height: $footer-size;
}

footer {
  min-height: $footer-size;
}

.navbar-brand {
  padding: 0 !important;
  height: 60px !important;
  margin-top: -10px;
}

.navbar-brand img {
  height: 100%;
  width: auto;
}

.navbar-default {
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: transparent !important;
}

.navbar-default .navbar-nav>li>a {
  color: $menu-grey !important;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  border-bottom: 0px solid transparent;
}

.navbar-default .navbar-nav>li>a:hover {
  color: $orange-main !important;
  border-bottom: 1px solid $orange-main;
}

.navbar-default .navbar-nav>li>a:active {
  color: $orange-main !important;
  border-bottom: 1px solid $orange-main;
}

.navbar-toggle {
  margin-right: 0px !important;
}

.banner-main {
  position: relative;
  width: 100%;
  z-index: 0;
  padding-top: 120px;
  padding-bottom: 20px;
}

.banner-main-alt {
  position: relative;
  width: 100%;
  z-index: 0;
  padding-top: 120px;
}

.banner-content {
  padding: 50px 0px 20px 0px;
}

.banner-content h1 {
  font-size: $title-xlg;
  font-weight: 800;
  color: $dark-grey;
}

.banner-content p {
  font-size: $title-xs;
  font-weight: 400;
  line-height: $title-sm;
  color: $grey;
}

.banner-content button {
  margin-top: 25px;
}

.banner-content2 {
  padding: 15px 20px 15px 90px;
  border-radius: 20px;
  margin-top: 45px;
  margin-left: 0px;
  background-image: url(../../../wwwroot/images/2021/hero-nhs-character.svg);
  background-repeat: no-repeat;
  background-position: left -10px bottom -10px;
  background-size: 90px;
  background-color: #e8f3fd;
  max-width: 280px;
}

.banner-content2 h1 {
  font-size: $title-lg;
  font-weight: 800;
  color: $dark-grey;
  margin: 0;
}

.banner-content2 p {
  font-size: 16px;
  font-weight: 800;
  line-height: 30px;
  color: #007ac3;
}

.btn-ban-nhs {
  font-size: 12px;
  line-height: 12px;
  font-weight: 800;
  color: #e78b44;
  border: 1px solid #e78b44;
  padding: 6px 10px;
  border-radius: 30px;
  transition: all .3s ease;
}

.btn-ban-nhs:hover {
  color: #fff;
  background-color: #e78b44;
}

.home-banner {
  background-image: url(../../../wwwroot/images/hp-wave.svg);
  background-position: bottom -1px center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: $orange-bg;
  padding-bottom: 15px;
  margin-bottom: 80px;
}

.company-banner {
  background-image: url(../../../wwwroot/images/comp-ban.png);
  background-size: 45% auto;
  background-position: bottom 30px right 15%;
  background-repeat: no-repeat;
  background-color: $blue-banner;
}

.comp-ban-alt {
  background-image: url(../../../wwwroot/images/hp-wave.svg);
  background-size: 100% auto;
  background-position: bottom -1px center;
  background-repeat: no-repeat;
  padding-bottom: 120px;
}

.contact-banner {
  background-size: 45% auto;
  background-position: bottom 30px right 15%;
  background-repeat: no-repeat;
  background-color: $light-blue;
}

.error-banner {
  background-size: 45% auto;
  background-position: bottom 30px right 15%;
  background-repeat: no-repeat;
  background-color: $orange-bg;
}

.error-ban-alt {
  background-image: url(../../../wwwroot/images/hp-wave.svg);
  background-size: 100% auto;
  background-position: bottom -1px center;
  background-repeat: no-repeat;
  padding-bottom: 120px;
}

.nhs-banner {
  background-image: url(../../../wwwroot/images/hp-wave.svg);
  background-position: bottom -1px center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: $blue-banner;
}

.article-banner {
  background-size: 45% auto;
  background-position: bottom 30px right 15%;
  background-repeat: no-repeat;
  background-color: $blue-banner;
}

.hb-banner {
  background-image: url(../../../wwwroot/images/hp-wave.svg);
  background-position: bottom -1px center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: $orange-bg;
  padding-bottom: 15px;
  margin-bottom: 80px;
}










footer {
  background-color: $orange-main;
  color: $white;
}

.footer-sec-top{
    padding: 40px 0px 20px 0px;
}

.footer-sec-bottom {
  padding: 28px 0px;
  font-size: 14px;
  border-top: 1px solid #f19d5d;
  font-weight: 500;
}

.main-footer > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

.main-footer > div:nth-child(1) > div:nth-child(1) {
  width: 25%;
}

.main-footer > div:nth-child(1) > div:nth-child(1) img{
    max-width: 100px;
}

.main-footer > div:nth-child(1) > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  width: 73%;
  padding-top: 30px;
}

.main-footer > div:nth-child(1) > div:nth-child(2) h4 {
    font-weight: 700;
    margin-bottom: 25px;
}

.main-footer > div:nth-child(1) > div:nth-child(1) ul,
.main-footer > div:nth-child(1) > div:nth-child(2) > div > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.main-footer > div:nth-child(1) > div:nth-child(1) ul li,
.main-footer > div:nth-child(1) > div:nth-child(2) > div > ul li {
  font-size: 14px;
  margin-top: 8px;
}

.main-footer > div:nth-child(1) > div:nth-child(1) ul li a,
.main-footer > div:nth-child(1) > div:nth-child(2) > div > ul li a {
    text-decoration: none;
    color: #fff;
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
    transition: border 0.3s ease
}

.main-footer > div:nth-child(1) > div:nth-child(1) ul li a:hover,
.main-footer > div:nth-child(1) > div:nth-child(2) > div > ul li a:hover {
  border-bottom: 1px solid #efefef;
}

.main-footer > div:nth-child(2) {
  text-align: center;
}

.main-footer > div:nth-child(2) p {
  margin:0;
  padding:0;
}

a.footer-follow-link {
  padding: 10px 0px 10px 40px !important;
  background-image: url(../../../wwwroot/images/LinkedIn.svg);
  background-position: left center;
  background-size: 30px;
  background-repeat: no-repeat;
  border: 0px solid transparent !important;
}
a.footer-follow-link:hover {
  border: 0px solid transparent !important;
}






.emergency-sec-outer {
  text-align: center;
  font-size: $content-sm;
  padding: 20px;
  color: $white;
  background-color: $dark-grey;
}

.emergency-sec-inner {}

.emergency-sec-inner a {
  text-decoration: none;
  color: $red;
}

.emergency-sec-inner a:hover {
  text-decoration: none;
  color: $red;
}

.emergency-sec-inner a span {
  text-decoration: none;
  color: $white;
  background-color: $red !important;
  margin: 0px 10px;
  font-size: $content-xs;
  padding: 3px 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.afil-outer {
  margin: 30px auto;
  max-width: 550px;
}

.afil-img a {
  margin: 0px auto;
  display: block;
  max-width: 90px;
}

.afil-img a img {
  max-width: 90px;
  margin: 0px auto;
}










/*Home Styling*/

.content {
  padding: 50px 0px 30px 0px;
}

section {
  padding: 0px 0px 50px 0px;
}

section h1 {
  font-size: $content-xlg;
  font-weight: 800;
  color: $dark-grey;
}

section h2 {
  font-size: $content-md;
  font-weight: 800;
  color: $dark-grey;
}

section p {
  font-size: $content-md;
  font-weight: 400;
  line-height: $content-lg;
  color: $grey;
  margin-top: 20px;
}

.icon-sec {
  text-align: center;
  font-size: $content-sm;
  line-height: $content-sm;
  color: $grey;
}

.icon-sec img {
  margin-bottom: 15px;
  max-width: 60px;
}

.icon-sec-left {
  text-align: left;
  font-size: $content-sm;
  line-height: $content-sm;
  color: $grey;
  margin-bottom: 30px;
}

.icon-sec-left img {
  margin-bottom: 15px;
  max-width: 60px;
}

.sec-3 {
  background-image: url(../../../wwwroot/images/hp-wave-2.svg);
  background-position: top -1px center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: $blue-bg;
  text-align: center;
}

.sec-3 img {
  max-width: 200px;
  margin-bottom: 50px;
}

.sec-inner {
  max-width: 350px;
  margin: 0px auto;
}

.sec-4 {
  text-align: center;
}

.sec-4 p {
  font-size: $content-sm;
  font-style: italic;
  max-width: 300px;
  margin: 30px auto 10px auto;
}

.sec-4 p:last-child {
  margin-top: 0px;
}

.sec-5 {
  background-color: $blue-bg;
}

.ad-sec {
  width: 100%;
  background-color: $white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 75px 40px;
  max-width: 750px;
  margin: 20px auto;
}

.ad-sec h1 {
  max-width: 200px;
}

.ad-sec p {
  max-width: 280px;
}

.ad-bg-1 {
  background-image: url(../../../wwwroot/images/ad-sec-1.jpg);
  background-position: bottom -40px right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.ad-bg-1-alt {
  background-image: url(../../../wwwroot/images/ad-sec-2.jpg);
  background-position: bottom 0px right;
  background-repeat: no-repeat;
  background-size: 150% auto;
  border: 1px solid $light-grey;
}

.ad-bg-2 {
  background-image: url(../../../wwwroot/images/ad-sec-2.jpg);
  background-position: bottom -40px right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.ad-bg-2-alt {
  background-image: url(../../../wwwroot/images/ad-sec-1.jpg);
  background-position: bottom 0px right;
  background-repeat: no-repeat;
  background-size: 150% auto;
  border: 1px solid $light-grey;
}

.ad-bg-3 {
  background-image: url(../../../wwwroot/images/ad-sec-3.jpg);
  background-position: bottom -40px right -50px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.ad-bg-3-alt {
  background-image: url(../../../wwwroot/images/ad-sec-3.jpg);
  background-position: bottom 0px right;
  background-repeat: no-repeat;
  background-size: 150% auto;
  border: 1px solid $light-grey;
}

/*Pro page styling*/

.pro-sec-3 {
  background-color: $blue-bg-alt;
  padding: 0;
}

.pro-sec-3-inner {
  background: url(../../../wwwroot/images/pro-sec-3-bg-1.svg) center top -100px no-repeat, url(../../../wwwroot/images/pro-sec-3-bg-2.svg) center bottom -100px no-repeat;
  background-size: 100% auto;
}

.pro-sec-3-inner div h1 {
  text-align: center;
}

.pro-sec-3-main-outer {
  padding-bottom: 40px;
}

.pro-sec-3-content {
  margin: 10px auto 10px auto;
}

.pro-sec-3-text {
  margin-top: 50px;
}

.pro-sec-3-img {
  margin-top: 30px;
}

.pro-sec-3-img-alt img {
  display: block;
  max-width: 380px;
  margin: 0px auto;
}

.pro-sec-3-img-icon {
  max-width: 50px;
  margin: 0px auto;
  margin-top: 20px;
}

.pro-sec-4-outer {
  background-color: $orange-bg;
}

.pro-sec-4-inner {
  background-image: url(../../../wwwroot/images/hp-wave-3.svg);
  background-position: top -100px center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: $orange-bg;
  text-align: center;
  padding-top: 180px;
}

/*Comp page styling*/

.comp-sec-1-outer {
  margin-top: 40px;
}

.comp-sec-1 {
  text-align: center;
}

.comp-sec-1-title {
  text-align: center !important;
}

.comp-vid {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  margin: 0px auto;
}

.comp-vid iframe,
.comp-vid object,
.comp-vid embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.comp-sec-2-outer {
  background-color: $orange-bg;
  padding-top: 100px;
  background-image: url(../../../wwwroot/images/hp-wave-2.svg);
  background-position: top -170px center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  text-align: center;
}

.comp-sec-2-outer h1 {
  text-align: center;
}

.comp-sec-2-outer p.alt-p {
  text-align: center;
  margin-top: 5px;
  color: $orange-main;
}

.comp-last-sec>div.content h1 {
  text-align: center !important;
}

.comp-last-sec>div.content h1 {
  text-align: center !important;
}

.comp-last-sec>.content {
  text-align: center;
  padding: 40px 0px 20px 0px;
}

.comp-last-sec>.content button {
  margin: 20px 0px;
}

.comp-last-sec>.pro-sec-2-icon-sec>.col-xs-4 {
  text-align: center;
}

.comp-sec-3 {
  text-align: left;
  background-image: url(../../../wwwroot/images/comp-sec-3-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.comp-sec-3>col-sm-12 {
  margin: 15px;
}

.comp-sec-3>.col-sm-12>div img {
  max-height: 185px;
  width: auto;
}

.comp-sec-3-alt {
  text-align: left;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.comp-sec-3-alt>col-sm-12 {
  margin: 15px;
}

.comp-sec-3-alt>.col-sm-12>div img {
  max-height: 185px;
  width: auto;
}

.comp-sec-1-outer>div>div>.col-sm-4 {
  text-align: center;
}

.error-img {
  position: relative;
  z-index: 99;
  max-width: 600px;
  margin: 0px auto;
  margin-top: -290px;
}

.error-sec-outer {}

.error-sec-outer h1 {
  text-align: center;
  font-size: 40px;
  margin-top: 30px;
}

.error-sec-outer p {
  text-align: center;
  font-size: 30px;
  max-width: 400px;
  margin: 20px auto 40px auto;
  line-height: 32px;
}

.error-sec-outer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 300px;
  margin: 0px auto;
}

.error-sec-outer ul li {
  margin-bottom: 2px;
}

.contact-img-sec {
  margin-top: -330px;
  position: relative;
  z-index: 1;
}

.contact-img {
  max-width: 750px;
  margin: 0px auto;
}

.contact-content-sec {
  max-width: 550px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contact-content-sec-alt {
  margin-top: 30px;
}

.contact-spacer {
  min-height: 160px;
}

.mb-form-outer {
  margin-top: 40px;
}

.mb-form-sec {
  margin-bottom: 30px;
}

.mb-form-sec-title {
  margin-bottom: 5px;
  font-weight: 600;
}

.mb-form-sec-title span {
  background-color: $orange-main;
  color: $white;
  font-size: $content-xs;
  padding: 2px 6px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  top: -2px;
  position: relative;
  margin-left: 8px;
}

.mb-form-sec-element {}

.mb-form-sec-element input {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 15px 20px;
  border: 0;
  background-color: $light-grey;
  outline: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.mb-form-sec-element input:hover {
  background-color: $light-grey-alt;
}

.mb-form-sec-element input:focus {
  background-color: $light-grey-alt;
}

.mb-form-sec-element textarea {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 15px 20px;
  min-height: 150px;
  resize: none;
  border: 0;
  background-color: $light-grey;
  outline: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.mb-form-sec-element textarea:hover {
  background-color: $light-grey-alt;
}

.mb-form-sec-element textarea:focus {
  background-color: $light-grey-alt;
}

.form-error-sec {
  position: relative;
  z-index: 0;
  padding: 18px 15px 6px 15px;
  margin-top: -10px;
  width: 100%;
  font-size: $content-xs;
  color: $white;
  background-color: $red;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.form-error-sec-textarea {
  position: relative;
  z-index: 0;
  padding: 22px 15px 8px 15px;
  margin-top: -18px;
  width: 100%;
  font-size: $content-xs;
  color: $white;
  background-color: $red;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.panel-outer {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  margin: 50px auto;
  max-width: 600px;
  overflow: hidden;
  border: 1px solid $light-grey-alt;
}

.contact-panel {
  background-image: url(../../../wwwroot/images/ad-sec-1.jpg);
  background-position: bottom -5px center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 60px 40px 100px 40px;
  text-align: center;
}

.panel-content {
  margin: 25px auto;
}

.legal-content {
  max-width: 700px;
  margin: 0px auto;
  text-align: justify;
}

.legal-content h2 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.legal-content p {
  font-size: $content-sm;
  margin-top: 15px;
}

.legal-content ol {
  margin-bottom: 20px;
}


.legal-content li {
  margin-bottom: 8px;
}

/*Professional Profile page*/
.profile-image {
  width: 200px;
  height: 200px;
  -moz-border-radius: 260px;
  -webkit-border-radius: 260px;
  border-radius: 260px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  overflow: hidden;
  margin: 20px auto;
}

.profile-image img {
  width: 100%;
}

.profile-name {
  font-size: $title-md;
  line-height: $title-lg;
  font-weight: 200;
}

.profile-sec-wrap {
  padding: 8px 8px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  margin-top: 15px;
  background-color: $light-grey-alt;
}

.profile-sec-wrap-alt {
  padding: 6px 6px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  margin-top: 5px;
  background-color: $light-grey-alt;
}

.profile-sec-wrap-themed {
  padding: 8px 0px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  margin-top: 6px;
  background-color: $orange-main;
}

.profile-sec-wrap-themed>.panel-sub-title-alt,
.profile-sec-wrap-themed>div>.panel-content {
  color: $white !important;
}

.profile-sec-wrap-themed>div>.pref-option-check>.checkbox .cr,
.radio .cr {
  background-color: #fff;
  border: 1px solid #fff
}


.profile-sec-wrap p {
  margin: 5px auto;
  font-size: 14px;
  font-weight: 600;
}

.profile-title {
  font-size: $content-sm;
  font-weight: 600;
  color: $grey-alt;
}

.profile-content {
  font-size: $content-md;
}

.profile-content-alt {
  font-size: $content-xlg;
  font-weight: 200;
  background-color: $light-grey;
  color: $grey;
  margin-top: 10px;
  padding: 10px 0px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.profile-content-alt img {
  width: 60px;
  height: 60px;
  margin: 0px auto;
}

.type-sec-icon {
  padding-left: 0;
  width: 60px;
  height: 60px;
  margin: 8px auto;
}

.type-sec-icon img {
  width: 100%;
}

.profile-price-sec {
  margin: 10px 0px;
  overflow: hidden;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

/* Panels */
.content-panel-wrapper {
  padding: 0px 0px 100px 0px;
}

.panel {
  width: 100% !important;
  background-color: $light-grey !important;
  text-align: center !important;
  margin: 13px 0px !important;
  border: 1px solid $light-grey !important;
  -moz-border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  border-radius: 15px !important;
  behavior: url(/css/border-radius.htc);
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  overflow: auto !important;
  padding: 15px 0px !important;
  box-shadow: none !important;
}

.panel:hover {
  border: 1px solid $light-grey-alt !important;
}

.panel-clear {
  width: 100% !important;
  background-color: transparent !important;
  text-align: center !important;
  margin: 13px 0px !important;
  border: 1px solid transparent !important;
  -moz-border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  border-radius: 15px !important;
  behavior: url(/css/border-radius.htc);
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  overflow: auto !important;
  padding: 20px 5px !important;
  box-shadow: none !important;
}

.panel-min {
  width: 100% !important;
  background-color: $light-grey !important;
  text-align: center !important;
  margin: 3px 0px !important;
  border: 1px solid $light-grey !important;
  -moz-border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  border-radius: 8px !important;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  overflow: auto !important;
  padding: 10px 0px !important;
  box-shadow: none !important;
}

.panel-approved {
  max-width: 400px;
  overflow: auto;
  padding: 0px;
  background-color: $orange-main;
  text-align: center;
  margin: 13px auto;
  border: 1px solid $orange-main;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  color: $white;
}

.panel-empty {
  width: 100% !important;
  text-align: center !important;
  overflow: auto !important;
  box-shadow: none !important;
}

.panel-auth {
  width: 100% !important;
  background-color: $light-grey !important;
  text-align: center !important;
  border: 1px solid $light-grey !important;
  -moz-border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  border-radius: 15px !important;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  overflow: auto !important;
  box-shadow: none !important;
}

.panel-auth:hover {
  border: 1px solid $light-grey-alt !important;
}

.false-calendar {
  background-image: url(/images/false-calendar.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 721px auto;
  min-height: 310px;
  margin: 22px auto;
  position: relative;
}

.false-login {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.false-login p {
  font-size: $content-lg;
  color: $grey;
  text-align: center;
  font-weight: 700;
  line-height: 30px;
}

.about-outer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
}

.about-sec-l {
  grid-area: 1 / 1 / 2 / 2;
}

.about-sec-r {
  grid-area: 1 / 2 / 2 / 3;
}

.about-outer-alt {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
}

.about-sec-c {
  grid-area: 1 / 2 / 2 / 3;
}

.about-panels {
  background-color: $white;
  margin: 20px auto;
  padding: 40px 20px;
  border-radius: 90px;
}

.about-img {
  max-width: 200px;
  margin: 0px auto;
}

.about-img img {
  width: 100%;
  border-radius: 100px;
}

.about-info {
  text-align: center;
}

.about-info h2 {}

.about-info h3 {}

.about-info p {
  padding: 0px 40px;
  font-size: 14px;
  text-align: left;
}

.nhs-banner-content {
  font-size: 45px;
  line-height: 50px;
  font-weight: 800;
  margin-top: 50px;
}

.nhs-banner-content span {
  font-size: 65px;
}

.nhs-logo-banner-sec {
  font-size: 50px;
  font-weight: 700;
  color: #007ac3;
  margin-top: 10px;
  line-height: 60px;
}

.nhs-logo-banner-sec img {
  max-width: 120px;
  float: left;
  margin-right: 15px;
}

.nhs-list-sec-wrap {
  margin-top: 60px
}

.nhs-list-sec strong {
  font-size: 16px;
  line-height: 18px;
}

.nhs-list-sec {
  margin: 10px 0px;
}

.numb-sec {
  width: 32px;
  height: 32px;
  border: 2px solid $orange-main;
  color: $orange-main;
  border-radius: 100px;
  padding: 0px;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
}

.nhs-sec-3-bg {
  background-color: $orange-bg;
  padding: 0 !important;
}

.nhs-sec-3-bg-inner {
  background: url(../../../wwwroot/images/wave-thin-bg-1.svg) center top -5px no-repeat, url(../../../wwwroot/images/wave-thin-bg-2.svg) center bottom -5px no-repeat;
  background-size: auto;
}

.nhs-sec-3-bg-inner div h1 {
  text-align: center;
}

.nhs-sec-3-main-outer {
  padding: 100px 0px 200px 0px;
  background-image: url(../../../wwwroot/images/dots-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
}

.panel-bg {
  background-color: $orange-bg;
  padding: 20px 20px 30px 20px;
  border-radius: 20px;
}

.contact-list-sec {
  background-color: $light-grey-alt;
  padding: 5px;
  border-radius: 20px;
  margin: 10px 0px;
}

.contact-list-sec h4 {
  text-align: center;
}

.contact-list-sec ul {
  padding: 0;
  margin: 0;
  padding: 10px;
  background-color: $white;
  border-radius: 16px;
}

.contact-list-sec ul li {
  list-style-type: none;
  border-bottom: 1px solid $light-grey;
  padding: 5px 0px;
  position: relative;
  display: block;
}

.contact-list-sec ul li:last-child {
  list-style-type: none;
  border-bottom: 0px solid $light-grey;
}

.contact-list-sec ul li a {
  text-decoration: none;
  position: relative;
  width: 100%;
  display: block;
  color: $grey;
}

.contact-list-sec ul li a strong {
  font-size: 14px;
}

.contact-list-sec ul li span,
.contact-list-sec ul li a span {
  float: right;
  position: absolute;
  right: 0;
  top: -2px;
  font-size: 12px;
  background-color: $orange-bg;
  color: $dark-grey;
  padding: 4px 5px;
  border-radius: 5px;
}

.contact-list-sec ul li a:hover span {
  background-color: $orange-main;
  color: #fff;
}

.contact-list-sec ul li:hover span {
  background-color: $orange-main;
  color: #fff;
}

.afil-bg {
  text-align: center;
  padding: 50px 0px;
  background-color: #e9f5f5;
}

.afil-bg img {
  max-width: 140px;
  margin: 20px 0px;
}

.afil-title h3 {
  font-size: 30px;
  font-weight: 800;
  color: #62c0c4;
}

.dropdown-menu {
  min-width: 210px !important;
}


.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  color: $white !important;
  background-color: $orange-main !important;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid $orange-main;
}

.navbar-nav>li>.dropdown-menu {
  background-color: $orange-main;
  padding: 10px;
  border: 1px solid $orange-main;
  color: $white;
  -moz-border-radius: 10px 0px 10px 10px !important;
  -webkit-border-radius: 10px 0px 10px 10px !important;
  border-radius: 10px 0px 10px 10px !important;
}

.navbar-nav>li>.dropdown-menu a {
  color: $white;
  text-decoration: none;
  padding: 4px 8px;
  display: block;
  border: 1px solid $orange-main;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.navbar-nav>li>.dropdown-menu a:hover {
  border: 1px solid $white;
}

.article-sec,
.bform-wrap {
  background-color: $orange-bg;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
  padding: 80px;
  max-width: 900px;
  margin: 10px auto 30px auto;
}

.article-sec img {
  width: auto;
  max-width: 100%;
  margin: 20px auto;
}

.bform-wrap {
  background-color: $orange-bg;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  padding: 30px;
  max-width: 1000px;
  margin: -50px auto 0px auto;
}

.bform-sec {
  position: relative;
}

.bf-title {
  font-size: $content-md;
  margin-bottom: 8px;
  margin-top: 12px;
}

.bform-sec input[type="text"] {
  width: 100%;
  border: 0;
  padding: 12px 12px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  outline: none;
  position: relative;
  z-index: 9;
}

.bform-sec select {
  width: 100%;
  border: 0;
  padding: 12px 12px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  outline: none;
  position: relative;
  z-index: 9;
}

.bform-sec textarea {
  width: 100%;
  border: 0;
  padding: 12px 12px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  resize: none;
  min-height: 200px;
  outline: none;
  position: relative;
  z-index: 9;
}

.panel-mid-rounded {
  position: relative;
  -moz-border-radius: 500px;
  -webkit-border-radius: 500px;
  border-radius: 500px;
  padding: 30px 50px;
  max-width: 800px;
  margin: 0px auto;
  margin-bottom: 100px;
  margin-top: -160px;
  overflow: hidden;
  background-color: $orange-bg;
}

.covid-panel-bg {
  background-position: left 40px bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../../wwwroot/images/covid-panel-bg.png);
}

.panel-mind-inner {
  padding-left: 200px;
}

.panel-mind-inner h2 {
  font-size: $title-xs;
  font-weight: 700;
}

.panel-mind-inner p {
  font-size: $content-md
}

.stat-sec-wrap-outer {}

.stat-sec-wrap {
  position: relative;
  margin-top: 100px;
  margin-bottom: 30px;
}

.stat-icon-sec {
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  text-align: center;
  padding: 10px 10px;
  margin-top: -50px;
}

.stat-icon-1 {
  background-image: url(../../../wwwroot/images/work-sec-bubbles-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-icon-2 {
  background-image: url(../../../wwwroot/images/work-sec-bubbles-2.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-icon-3 {
  background-image: url(../../../wwwroot/images/work-sec-bubbles-3.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-icon-4 {
  background-image: url(../../../wwwroot/images/work-sec-bubbles-4.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-icon-5 {
  background-image: url(../../../wwwroot/images/work-sec-bubbles-5.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-icon-6 {
  background-image: url(../../../wwwroot/images/work-sec-bubbles-6.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-icon-sec img {
  background-color: $orange-bg;
  padding: 5px;
  border-radius: 100px;
  max-width: 110px;
}

.stat-content-sec {
  text-align: center;
  padding: 60px 30px 20px 30px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: #FFFAF5;
}

.stat-content-sec p {
  max-width: 205px;
  margin: 0px auto;
}

.work-sec-2-bg {
  background-color: $blue-bg;
  padding: 0 !important;
  margin-top: -50px;
}

.work-sec-2-bg-inner {
  background: url(../../../wwwroot/images/wave-thin-bg-4.svg) center top -200px no-repeat, url(../../../wwwroot/images/wave-thin-bg-3.svg) center bottom -330px no-repeat;
  background-size: auto;
}

.work-sec-2-main-outer {
  padding: 200px 0px 200px 0px;
  background-image: url(../../../wwwroot/images/dots-bg.png);
  background-position: center bottom -30px;
  background-repeat: no-repeat;
  background-size: 80%;
}

.work-vid-outer {}

.work-vid {
  margin-top: 60px;
}

.testimonial-sec {
  max-width: 800px;
  margin: 70px auto 0px auto;
  background-color: $blue-bg;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
  padding: 30px;
  position: relative;
}

.testimonial-img-left {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.testimonial-img-left img {
  width: 100%;
}

.testimonial-content-wrap-left {
  padding-left: 130px;
  padding-right: 40px;
}

.testimonial-img-right {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.testimonial-img-right img {
  width: 100%;
}

.testimonial-content-wrap-right {
  padding-right: 130px;
  padding-left: 40px;
}

.testimonial-content-title {
  font-size: $title-xs;
}

.testimonial-content-wrap {}

.work-dash-img {
  max-width: 800px;
  margin: 40px auto;
}

.work-dash-img img {
  width: 100%;
}

.mid-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.work-sec-4-img {
  max-width: 350px;
  padding: 10px 0px;
}

.testimonial-bg {
  background-image: url(../../../wwwroot/images/dots-bg-orange.svg);
  background-position: top center;
  background-repeat: no-repeat;
}

.business-sec-2-wrap {
  padding: 20px 0px 80px 0px;
}

.business-sec-2-img {
  max-width: 250px;
  margin: 0px auto;
}

.business-sec-2-img img {
  width: 100%;
}

.business-sec-2-content {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  line-height: 32px;
  font-weight: 800;
}

.b-form-ban-img img {
  max-width: 300px;
}

.nhs-logo {
  display: inline-block;
  margin-top: -7px;
}

.work-ban {
  margin-left: 50px;
}

.afil-sec-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.afil-sec-wrapper > div {
  width:20%;
}
.afil-sec-wrapper > div > img{
  width: 100%;
}

/* Blog Styling */
.blog-body {
  background-color: #fbf7f3;
  padding-bottom: 40px;
}
.banner-blog {
  text-align: center;
}
.blog-bg {
  background-image: url(../../../wwwroot/images/mb-blog-banner.svg);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
}
.blog-header {
  padding-top: 110px;
  padding-bottom: 20px;
}
.blog-banner-content {
}
.blog-banner-content h1 {
  font-size: 40px;
  font-weight: 800;
  color: #222222;
}
.blog-posts-wrapper {
  margin-top: 20px;
  border-radius: 40px;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}
.blog-post-card {
  width: 31%;
  margin: 10px 1.16%;
  background-color: #f7d6bca3;
  border-radius: 25px;
  overflow: hidden;
}
.blog-post-card-image {
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.blog-post-card-image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.blog-post-card-content {
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 220px);
}
.blog-post-card-content h2 {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}
.blog-post-card-content h2 span {
  display: block;
  font-size: 14px;
  margin-top: 15px;
}
.blog-post-card-content p {
  flex: 1;
}
.blog-post-card-content a {
  display: inline-block;
  border-bottom: 1px solid transparent;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 5px 2px 1px 0px;
  text-decoration: none !important;
  transition: .3s;
  align-self: baseline;
}
.blog-post-card-content a:hover {
  color: #e78b44;
  border-bottom: 1px solid #e78b44;
  text-decoration: none !important;
}

.post-body-wrapper {
  max-width: 800px;
  margin: 0px auto;
  margin-top: 20px;
  padding: 40px;
  border-radius: 30px;
  background-color: #fff;
}

.post-image {
  border-radius: 15px;
  margin: 0px 0px 30px 0px;
  overflow: hidden;
}

.post-body-wrapper img {
    height: 350px;
    object-fit: cover;
}

.post-body-wrapper h1 {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.post-body-wrapper h2 {
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 800;
}

.post-body-wrapper h2 span {
  font-size: 16px;
}

.post-body-wrapper h3 {
  margin: 30px 0px 18px 0px;
  font-size: 18px;
  font-weight: 800;
}

  .post-body-wrapper p {
    font-size: 18px;
    margin-bottom: 30px;
  }

.post-body-wrapper h4 {
  font-weight: 800;
  font-size: 22px;
  margin-top: 35px;
}

.post-body-wrapper ul {
  margin: 20px 0px;
  font-size: 18px;
}

.post-body-wrapper ul li.list-paragraph {
    margin: 10px 0px
}

  .post-body-wrapper p:last-child {
    margin-bottom: 0px;
  }

  .hb-logo-sec {
    width: 100%;
    margin: 30px 0px 40px 0px;
  }

  .hb-logo-sec img {
    max-width: 72px;
  }

  .hb-form-wrapper {
    max-width: 700px;
    margin: 100px auto 0px auto;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    background-image: url(../../../wwwroot/images/wizard-bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 120%;
  }

  .hb-form-wrapper > form {
    width: 100%;
    min-height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hb-form-wrapper > form > div {
    max-width: 650px;
    margin: 20px 20px;
  }

  .hb-form-wrapper > form > div > div {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    text-align: left;
  }

  .hb-form-wrapper > form > div > div h1,
  .hb-form-wrapper > form > div > div h2 {
    text-align: center;
    max-width: 460px;
  }

  .hb-form-wrapper > form > div > div h1 {
    max-width: 340px;
    margin: 0px auto;
    margin-bottom: 6px;
  }

  .hb-form-wrapper > form > div > div label {
    font-size: 22px;
  }

  .hb-form-wrapper > form > div > div input {
    position: relative;
    z-index: 10;
    width: 100%;
    padding: 15px 20px;
    border: 0;
    background-color: $light-grey;
    outline: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }

  .hb-form-wrapper > form > div > div input:hover {
    background-color: $light-grey-alt;
  }

  .hb-form-wrapper > form > div > div input:focus {
    background-color: $light-grey-alt;
  }

  .hb-footer {
    width: 100%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 20px 0px;
    background-color: #e78b44;
  }

  .hb-footer p {
    margin: 0;
    padding: 0;
  }

  .hb-banner-btn-sec {
    text-align: left;
  }

  .home-banner > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .home-banner > div > div:nth-child(1) {
    width: 35%;
  }

  .home-banner > div > div:nth-child(2) {
    width: 60%;
  }

  .home-latestnews {
    padding: 40px 0px;
    margin-bottom: 50px;
  }

  .home-latestnews > div > h2 {
    font-size: 30px;
    font-weight: 800;
    color: #222222;
    margin: 0;
    margin-bottom: 30px;
  }

  .home-latestnews > div > div {
    display: flex;
    justify-content: space-between;
  }

  .home-latestnews > div > div > div {
    width: 32%;
  }

  .home-latestnews > div > div > div > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }

  .home-latestnews > div > div > div > h3 {
    font-size: 20px;
    font-weight: 700;
  }

  .home-latestnews > div > div > div > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: #3d3d3d;
    margin-top: 20px;
    height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .home-latestnews > div > div > div > a {
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 800;
    padding-right: 40px;
    outline: none;
    text-decoration: none;
    transition: color 0.3s ease;
  }

.home-latestnews > div > div > div > a:hover {
    color:#e78b44;
}

  .home-pros {
    background-image: url(../../../wwwroot/images/2021/hp-bg-pattern-pro.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 80px;
  }

  .home-pros > div:nth-child(1) {
    width: 100%;
    min-height: 200px;
    background-image: url(../../../wwwroot/images/2021/hp-bg-curve-pro-top.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 150%;
  }

  .home-pros > div:nth-child(2) {
    margin-top: -90px;
    margin-bottom: -90px;
  }

  .home-pros > div:nth-child(3) {
    width: 100%;
    min-height: 200px;
    background-image: url(../../../wwwroot/images/2021/hp-bg-curve-pro-bottom.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 150%;
  }

  .home-pros > div:nth-child(2) {
  }

  .home-pros > div:nth-child(2) > div > h2 {
    font-size: 30px;
    font-weight: 800;
    color: #222222;
    text-align: center;
    max-width: 500px;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .home-pros > div:nth-child(2) > div > p {
    text-align: center;
    max-width: 750px;
    margin: 0px auto;
    margin-bottom: 40px;
    font-size: 18px;
  }

  .home-pros > div:nth-child(2) > div > div {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
  }

  .home-pros > div:nth-child(2) > div > div > div {
    width: 32%;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 0px 20px 20px 20px;
  }

  .home-pros > div:nth-child(2) > div > div > div > img {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-bottom: 15px;
    border-radius: 100%;
  }

  .home-pros > div:nth-child(2) > div > div > div > h4 {
    font-weight: 800;
    font-size: 19px;
  }

  .home-pros > div:nth-child(2) > div > div > div > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: #3d3d3d;
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .home-pros > div:nth-child(2) > div > div > div > a {
    color: #000;
    font-size: 16px;
    font-weight: 800;
    outline: none;
    text-decoration: none;
    display: block;
    margin: 25px 0px 5px 0px;
    transition: all .3s ease;
  }
.home-pros > div:nth-child(2) > div > div > div > a:hover {
  color: #e78b44;
}

  /* Responsive Styling */
  @media (max-width: 1200px) {
    .banner-content {
      padding: 35px 0px 20px 0px;
    }

    .banner-main {
      padding-top: 130px;
      padding-bottom: 80px;
    }

    .pro-sec-2-icon-sec {
      text-align: center !important;
    }

    .pro-sec-2-icon-sec > .col-xs-6 {
      margin-bottom: 20px;
      padding: unset;
    }

    .pro-sec-3-text {
      margin-top: 0px;
    }

    .pro-sec-3-img {
      margin-top: 50px;
    }

    .comp-sec-2-outer {
      padding-top: 80px;
      background-position: top -100px center;
    }

    .work-vid {
      margin-top: 160px;
    }

    .banner-content2 {
      background-position: left bottom -10px;
    }
  }

  @media (max-width: 992px) {

    .banner-content {
      padding: 0px 0px 20px 0px;
      max-width: 400px;
      text-align: center;
      margin: 30px auto;
    }

    .banner-main {
      padding-top: 120px;
      padding-bottom: 80px;
    }

    .banner-content h1 {
      font-size: $title-lg;
    }

    .banner-content p {
      font-size: $title-xs;
      line-height: $title-sm;
    }

    section h1 {
      font-size: $content-lg;
    }

    section h2 {
      font-size: $content-sm;
    }

    section p {
      font-size: $content-norm;
      line-height: 1.4em;
    }

    .pro-sec-3-inner {
      padding-top: 30px;
      background: url(../../../wwwroot/images/pro-sec-3-bg-1.svg) center top -60px no-repeat, url(../../../wwwroot/images/pro-sec-3-bg-2.svg) center bottom -60px no-repeat;
      background-size: 100% auto;
    }

    .pro-sec-4-inner {
      padding-top: 100px;
    }

    .blog-post-card {
      width: 46%;
      margin: 10px 2%;
    }

    .home-banner > div {
      flex-direction: column;
    }

    .home-banner > div > div:nth-child(1) {
      width: 100%;
      order: 2;
    }

    .home-banner > div > div:nth-child(2) {
      width: 100%;
      order: 1;
    }

    .banner-content2 {
      max-width: 290px;
      margin: 0px auto;
      text-align: left;
      padding: 15px 20px 15px 110px;
    }

    .home-pros > div:nth-child(2) > div > div > div > img {
      width: 150px;
      height: 150px;
      margin-top: -75px;
    }

    .home-pros > div:nth-child(2) > div > div > div {
      width: 32%;
      background-color: #fff;
      border-radius: 10px;
      text-align: center;
      padding: 0px 15px 20px 15px;
    }
  }

  @media (max-width: 767px) {
    .navbar-collapse {
      padding-right: 15px;
      padding-left: 15px;
      overflow-x: visible;
      -webkit-overflow-scrolling: touch;
      border-top: 0px solid transparent;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      max-width: 450px;
      margin: 0px auto !important;
    }

    .navbar-default .navbar-toggle {
      border-color: $menu-grey !important;
      background-color: $menu-grey !important;
    }

    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover {
      border-color: $orange-main !important;
      background-color: $orange-main !important;
    }

    .navbar-default .navbar-toggle .icon-bar {
      background-color: $white !important;
    }

    .navbar-nav {
      background-color: $orange-main;
      color: $white;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      overflow: hidden;
    }

    .navbar-nav > li > a {
      padding-top: 10px;
      padding-bottom: 8px;
      line-height: 10px;
      text-align: center;
    }

    .navbar-default .navbar-nav > li > a {
      color: $white !important;
    }

    .navbar-default .navbar-nav > li > a:focus,
    .navbar-default .navbar-nav > li > a:hover {
      color: $white !important;
      background-color: $orange-alt !important;
    }

    .navbar-nav .open .dropdown-menu {
      margin: 0px 15px 20px 15px !important;
      background-color: #da8340 !important;
      -webkit-border-radius: 10px !important;
      -moz-border-radius: 10px !important;
      border-radius: 10px !important;
    }

    .navbar-nav > li > .dropdown-menu a {
      border: 1px solid #da8340;
      text-align: center;
    }

    .blog-bg {
      background-size: 390% auto;
      background-position: left -330px top;
    }

    .blog-header {
      padding-top: 110px;
      padding-bottom: 30px;
    }

    .blog-banner-content h1 {
      margin: 0;
    }

    .blog-posts-wrapper {
      margin-top: 0px;
      border-radius: 35px;
      padding: 5px;
    }

    .blog-post-card {
      width: 100%;
      margin: 10px;
    }

    .post-body-wrapper h1 {
      font-size: 30px;
    }

    .post-body-wrapper {
      margin: 0px 15px;
      padding: 20px;
    }
  }

  @media (max-width: 768px) {
    .banner-main {
      margin-top: 0px;
      padding-top: 100px;
      padding-bottom: 120px;
      text-align: center;
    }

    .banner-main > div > div > img {
      display: none;
    }

    .banner-content {
      max-width: 450px;
      margin: 0px auto;
      margin-bottom: 40px;
    }

    .banner-content h1 {
      text-align: center;
    }

    .banner-content p {
      text-align: center;
    }

    section h1 {
      text-align: center;
    }

    section h2 {
      text-align: center;
    }

    section p {
      text-align: center;
      max-width: 550px;
      margin: 15px auto;
    }

    .ad-sec div h1 {
      text-align: left;
    }

    .ad-sec div p {
      text-align: left;
      width: 100%;
      margin: 15px 0px;
    }

    .content {
      padding: 20px 0px 30px 0px;
    }

    .icon-sec {
      margin-bottom: 30px;
    }

    .mobile-clear {
      clear: both;
    }

    .nopadding-left {
      padding-left: unset !important
    }

    .nopadding-mobile {
      padding: 0 !important;
    }

    .pro-sec-3-inner {
      padding-top: 30px;
      background: url(../../../wwwroot/images/pro-sec-3-bg-1.svg) center top -20px no-repeat, url(../../../wwwroot/images/pro-sec-3-bg-2.svg) center bottom -20px no-repeat;
      background-size: 100% auto;
    }

    .pro-sec-3-text {
      margin-top: 20px;
    }

    .pro-sec-3-img {
      margin-top: 50px;
      text-align: center;
    }

    .pro-sec-3-img img {
      max-width: 200px;
      margin: 0px auto;
    }

    .pro-sec-3-img-alt img {
      max-width: 340px;
    }

    .pro-sec-3-img-icon {
      max-width: 30px;
      margin-top: 40px;
    }

    .pro-sec-2-icon-sec > div > .col-sm-12 {
      text-align: center;
    }

    .pro-sec-4-inner {
      background-image: url(../../../wwwroot/images/hp-wave-3.svg);
      background-position: top -10px center;
      padding-top: 80px;
      padding-bottom: 40px;
    }

    .comp-sec-1-outer {
      margin-top: 0px;
    }

    .comp-last-sec > .content {
      text-align: center;
    }

    .comp-sec-1 {
      text-align: center;
      margin-top: 30px;
    }

    .comp-sec-2-outer {
      padding-top: 80px;
      background-position: top -30px center;
    }

    .comp-sec-3 {
      text-align: center;
      background: none;
    }

    .banner-btn {
      text-align: center;
    }

    .company-banner {
      background-image: none;
    }

    .comp-ban-alt {
      padding-bottom: 85px;
    }

    .mobile-centred-txt {
      text-align: center !important;
    }

    .mobile-centred-txt h1 {
      text-align: center !important;
    }

    .mobile-centred-txt h2 {
      text-align: center !important;
    }

    .mobile-centred-txt a {
      text-align: center !important;
    }

    .error-img {
      margin-top: -230px;
    }

    .contact-img-sec {
      margin-top: -180px;
    }

    .contact-img {
      max-width: 250px;
      margin: 0px auto;
    }

    .contact-content-sec {
      margin-top: 30px;
      text-align: center !important;
    }

    .contact-content-sec-alt {
      text-align: center !important;
    }

    .contact-spacer {
      min-height: 50px;
    }

    .contact-panel {
      padding: 30px 20px 100px 20px;
    }

    .panel-content h1 {
      font-size: $title-sm;
    }

    .legal-content {
      padding: 20px 20px;
    }

    .legal-content h2 {
      text-align: left !important;
    }

    .legal-content p {
      text-align: left !important;
    }

    .legal-content ol {
      text-align: left !important;
    }

    a.btn-main-link {
      padding: 3px 15px 3px 15px;
    }

    .about-outer {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .about-outer-alt {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .about-sec-l {
      grid-area: 1 / 1 / 2 / 3;
    }

    .about-sec-r {
      grid-area: 2 / 1 / 2 / 3;
    }

    .about-sec-c {
      grid-area: 1 / 1 / 2 / 3;
    }

    .nhs-list-sec strong {
      line-height: 18px;
    }

    .nhs-banner-content span {
      font-size: 35px;
    }

    .nhs-banner-content {
      font-size: 30px;
      line-height: 35px;
      margin-top: 39px;
    }

    .nhs-logo-banner-sec {
      font-size: 35px;
      margin-top: 0px;
    }

    .nhs-logo-banner-sec img {
      max-width: 110px;
      float: none;
      margin-right: 10px;
    }

    .nhs-banner {
      text-align: center;
    }

    .nhs-sec-3-main-outer {
      background-size: auto 100%;
      background-position: left bottom;
    }

    .work-sec-2-main-outer {
      padding: 0px 0px 50px 0px;
    }

    .work-sec-2-bg-inner {
      background: url(../../../wwwroot/images/wave-thin-bg-3.svg) center top 370px no-repeat, url(../../../wwwroot/images/wave-thin-bg-4.svg) center bottom 410px no-repeat;
      background-size: auto;
    }

    .work-sec-2-main-outer {
      background-size: auto 100%;
      background-position: left bottom;
    }

    .pull-right {
      float: unset !important;
    }

    .mobile-clear {
      clear: both;
    }

    .panel {
      padding: 10px 10px 20px 10px;
    }

    .panel-mid-rounded {
      margin-bottom: 80px;
      margin-top: -90px;
    }

    .work-vid {
      margin-top: 50px;
    }

    .work-sec-4-img {
      margin: 0px auto;
      padding: 70px 0px 0px 0px;
    }

    .pull-right {
      float: none !important;
    }

    .testimonial-sec {
      margin: 50px auto 0px auto;
    }

    .testimonial-img-left {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      margin: 0px auto 25px auto;
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
    }

    .testimonial-content-wrap-left {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }

    .testimonial-img-right {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      margin: 0px auto 25px auto;
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
    }

    .testimonial-content-wrap-right {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }

    .work-sec-2-bg {
      margin-top: 50px;
    }

    .b-form-ban-img {
      text-align: center !important;
    }

    .work-ban {
      margin-left: 0px;
    }

    .afil-sec-wrapper > div {
      width: 25%;
      margin: 0px 4%;
    }

    .home-latestnews > div > div > div {
      width: 100%;
      max-width: 450px;
      margin: 25px auto;
    }

    .home-latestnews > div > h2 {
      text-align: center;
    }

    .home-latestnews > div > div {
      flex-direction: column;
    }

    .home-pros > div:nth-child(2) > div > div {
      flex-direction: column;
    }

    .home-pros > div:nth-child(2) > div > div > div {
      width: 100%;
      max-width: 450px;
      margin: 0px auto;
      margin-bottom: 105px;
      padding: 0px 25px 20px 25px;
    }

    .home-pros > div:nth-child(1) {
      min-height: 140px;
      background-size: 100%;
    }

    .home-pros > div:nth-child(3) {
      min-height: 70px;
      background-size: 100%;
    }

    .main-footer > div:nth-child(1) {
      flex-direction: column;
    }

    .main-footer > div:nth-child(1) > div:nth-child(1) {
      width: 100%;
      order: 2;
      text-align: center;
    }

    .main-footer > div:nth-child(1) > div:nth-child(1) img {
      max-width: 80px;
      margin-bottom: -10px;
      margin-top: 30px;
    }

    .main-footer > div:nth-child(1) > div:nth-child(2) {
      width: 100%;
      order: 1;
      padding-top: 0px;
      margin-top: -20px;
      flex-wrap: wrap;
    }

    .main-footer > div:nth-child(1) > div:nth-child(2) > div {
      width: 45%;
      margin-top: 30px;
    }
  }

  @media (max-width: 576px) {

    .banner-main {
      padding-top: 150px;
      padding-bottom: 80px;
    }

    .banner-main > div > div > img {
      display: none;
    }

    .banner-content {
      padding: 0px 0px 20px 0px;
    }

    .banner-content h1 {
      font-size: $title-md;
    }

    .banner-content p {
      font-size: $content-md;
      line-height: $title-sm;
    }

    section p {
    }

    section {
      padding: 0px 0px 10px 0px;
    }

    .content {
      padding: 30px 0px 20px 0px;
    }

    .icon-sec {
      max-width: 400px;
      margin: 0px auto 25px auto;
    }

    .sec-2-img img {
    }

    .sec-3 {
      padding-top: 0px;
    }

    .sec-3 h1 {
      margin-top: -15px;
    }

    .sec-3 img {
      margin-bottom: 20px;
    }

    .sec-3 div div.col-sm-6 {
      margin: 50px auto;
    }

    .ad-sec {
      padding: 35px 40px 120px 40px;
      max-width: 750px;
    }

    .ad-bg-1 {
      background-position: bottom -1px right;
      background-size: 150%;
    }

    .ad-bg-2 {
      background-position: bottom -1px right;
      background-size: 150%;
    }

    .ad-bg-3 {
      background-position: bottom -1px right;
      background-size: 150%;
    }

    .comp-sec-3 > .col-sm-12 > div img {
      max-height: 185px;
      width: auto;
      margin: 0px auto;
      margin-top: 30px;
    }

    .comp-sec-3 > div > div > p {
      max-width: 350px;
      margin: 0px auto;
    }

    .small-text {
      max-width: 210px;
      margin: 0px auto;
    }

    .article-sec {
      padding: 30px;
      text-align: center;
    }

    .bform-wrap {
      padding: 20px;
      margin-top: -20px;
    }

    .bf-title {
      font-size: $content-sm;
    }

    .covid-panel-bg {
      background-image: none;
    }

    .panel-mind-inner {
      padding-left: 0px;
      text-align: center !important;
      display: block;
      position: relative;
    }

    .panel-mind-inner::after {
      content: "";
      background: url(../../../wwwroot/images/covid-panel-bg.png);
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }

    .panel-mid-rounded {
      border-radius: 20px;
      padding: 20px 20px;
      margin-bottom: 50px;
      margin-top: -100px;
    }

    .stat-sec-wrap {
      margin-left: 40px;
      margin-top: 0px;
      margin-bottom: 10px;
    }

    .stat-content-sec {
      text-align: left;
      padding: 20px 20px 20px 40px;
      border-radius: 20px;
    }

    .stat-icon-sec {
      position: absolute;
      left: -45px;
      right: unset;
      top: 45px;
      bottom: 0;
      padding: 10px 10px;
      margin-top: -50px;
    }

    .stat-icon-sec img {
      padding: 10px;
      border-radius: 100px;
      max-width: 70px;
    }

    .stat-icon-sec {
      background-image: none !important;
    }

    .business-sec-2-img {
      max-width: 180px;
    }

    .business-sec-2-content {
      font-size: 25px;
      margin-bottom: 50px;
    }

    .hb-form-wrapper > form > div > div {
      padding: 20px;
    }

    .hb-banner-btn-sec {
      text-align: center;
    }

    .home-banner {
      margin-bottom: 25px;
    }

    .home-latestnews {
      margin-top: 80px;
    }

    .main-footer > div:nth-child(1) > div:nth-child(2) > div {
      width: 100%;
      text-align: center;
    }
  }
