@import"colours.scss";

* {
  font-family: 'Nunito', sans-serif;
}

$title-xlg: 40px;
$title-lg: 35px;
$title-md: 30px;
$title-sm: 25px;
$title-xs: 20px;

$content-xlg: 30px;
$content-lg: 25px;
$content-md: 18px;
$content-norm: 16px;
$content-sm: 14px;
$content-xs: 10px;

/*Page Titles*/

.page-title {
  color: $grey;
  font-weight: 800;
  font-size: $content-xlg;
  margin: 0px 0px;
  padding: 40px 0px 10px 0px;
  text-align: center;
}

.page-title-alt {
  color: $grey;
  font-weight: 300;
  font-size: $content-md;
  margin: 0px 0px;
  padding: 40px 0px 10px 0px;
  text-align: center;
}

.page-sub-title {
  max-width: 500px;
  text-align: center;
  font-size: $content-sm;
  font-weight: 300;
  color: $grey-alt;
  margin: 0px auto;
  margin-bottom: 50px;
}

.page-sub-title-alt {
  max-width: 500px;
  text-align: center;
  font-size: $content-sm;
  font-weight: 300;
  color: $grey-alt;
  margin: 0px auto;
  margin-bottom: 30px;
}

/*Panel Titles*/

.panel-title {
  font-size: $title-xs !important;
  line-height: $title-xs !important;
  font-weight: 700;
  color: $grey;
  text-align: center;
  padding: 12px 0px 6px 0px;
  margin-bottom: 5px !important;
}

.panel-empty-title {
  font-size: $content-sm;
  font-weight: 700;
  color: $grey;
}

.panel-sub-title {
  font-size: $content-norm;
  font-weight: 800;
  color: $grey-alt;
}

.panel-sub-title-alt {
  font-size: $content-sm;
  font-weight: 800;
  color: $grey;
  text-align: center;
  padding: 5px 0px 5px 0px;
}

.panel-content {
  font-size: $content-sm;
  font-weight: 400;
  color: $grey-alt;
  margin: 15px auto;
  padding: 4px 20px;
  text-align: left;
}

.panel-sub-content {
  font-size: $content-xs;
  font-weight: 400;
  color: $grey;
  margin: 0px auto;
}

.panel-sub-content-alt {
  font-size: $content-norm;
  font-weight: 400;
  color: $grey;
  margin: 0px auto;
}

.general-content {
  font-size: 16px;
  font-weight: 600;
  text-align: left !important;
}


// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
}
